const changeHref = function(seoLinks) {
  for (let i = 0; i < seoLinks.length; i++) {
    const link = seoLinks[i];
    const seoLink = link.dataset.seoLink;

    const hasProtocol = seoLink.indexOf('http') === 0;
    const hasLeadingSlash = seoLink.indexOf('/') === 0;

    const prefix = (hasProtocol || hasLeadingSlash) ? '' : '/';

    link.href = prefix + seoLink;
  }
};

const updateSeoLinks = function() {
  const seoLinks = document.querySelectorAll('a[data-seo-link]');
  changeHref(seoLinks);

  setTimeout(() => {
    const secondPass = document.querySelectorAll('a[data-seo-link][href="#"]');
    changeHref(secondPass);
  }, 250);

  window.seoLinksReady = true;
  const event = new CustomEvent('SEO_LINKS__READY');
  window.dispatchEvent(event);
};

const init = () => {
  if (window.vueAppReady) {
    updateSeoLinks();
  } else {
    window.addEventListener('VUE_APP__MOUNTED', updateSeoLinks);
  }

  window.addEventListener('VUE_PLACEHOLDER__UPDATED', updateSeoLinks);
};

if (document.readyState !== 'loading') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', init);
}

